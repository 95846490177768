import { bindable, observable } from 'aurelia-framework';
import { SUMMARY_TYPE } from 'services/production-service';
import { TIME_PERIOD } from 'common/constants';
import { WidgetBase } from './../widget-base';
import { c } from 'common/common';

export class WidgetProductionSummary extends WidgetBase {
    static inject = [Element];

    @bindable settings;
    friendlyName;
    @observable({ changeHandler: '_dispatch' }) timePeriod = TIME_PERIOD.YTD;
    @observable({ changeHandler: '_dispatch' }) summaryType = SUMMARY_TYPE.Personal;

    timePeriods = [TIME_PERIOD.WTD, TIME_PERIOD.MTD, TIME_PERIOD.YTD];
    summaryTypes = [SUMMARY_TYPE.Personal, SUMMARY_TYPE.Team, SUMMARY_TYPE.Agency, SUMMARY_TYPE.MasterAgency];

    constructor(element) {
        super(element);
    }

    settingsChanged() {
        if (!this.settings) return;

        this._initializingSettings = true;
        this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
        this.timePeriod = this.settings ? this.settings.timePeriod || TIME_PERIOD.MTD : TIME_PERIOD.MTD;
        this.summaryType = this.settings ? this.settings.summaryType || SUMMARY_TYPE.Personal : SUMMARY_TYPE.Personal;
        this._initializingSettings = false;
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch(true);
    }

    _dispatch(save = false) {
        if (this._initializingSettings) return;
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
            timePeriod: this.timePeriod,
            summaryType: this.summaryType,
        };
        this.dispatchSettingsEvent(data, save);
    }
}
