import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { KPI } from 'common/constants';

export class WidgetVolumeRankings extends WidgetBase {
    static inject = [Element];

    @bindable settings;
    @bindable canEdit = false;
    forMetric = KPI.Metric.Premium;

    constructor(element) {
        super(element);
    }

    settingsChanged(detail) {
        if (!detail.data) return;
        this.settings = detail.data;
        this.dispatchSettingsEvent(detail.data, detail.save);
    }
}
