import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Config } from 'services/config';
import { Contests } from 'services/contests';
import { c } from 'common/common';

export class WidgetContestStandings extends WidgetBase {
    static inject = [Element, Config, Contests];
    _config;
    _contests;

    @bindable settings;
    friendlyName;
    @observable({ changeHandler: '_dispatch' }) code = '';
    _defaultCode = '';
    contests;

    constructor(element, config, contests) {
        super(element);
        this._config = config;
        this._contests = contests;
    }

    settingsChanged() {
        try {
            this._initializingSettings = true;
            this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
            this.code = this.settings ? this.settings.code || this._defaultCode : this._defaultCode;
        } catch (err) {
            console.log(err);
        } finally {
            this._initializingSettings = false;
        }
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch();
    }

    _dispatch() {
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
            code: this.code || this._defaultCode,
        };
        this.dispatchSettingsEvent(data, true);
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.contests = await this._contests.all();
            if (this.contests.length && !this.code) this.code = this.contests[0].code;
        } catch (err) {
            console.log(err);
        }
    }
}
