import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';

export class WidgetKpiHealth extends WidgetBase {
    static inject = [Element, Security];
    _security;

    @bindable settings;
    memberId;

    constructor(element, security) {
        super(element);
        this._security = security;
        this.memberId = this._security.authenticatedMemberId;
    }

    settingsChanged() {
    }
}
