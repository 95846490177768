import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { Onboardings } from 'services/onboardings';

export class WidgetOnboardingStatusChart extends WidgetBase {
    static inject = [Element, Security, Onboardings];
    _security;
    _production;

    @bindable settings;

    @observable({ changeHandler: '_dispatch' }) forAgency;
    _defaultForAgency = false;

    @observable({ changeHandler: '_dispatch'}) defaultChartType;
    chartData;
    CHART_TYPE = {
        ByStatus: 'by-status',
        ByStep: 'by-step',
    };
    chartType;

    constructor(element, security, onboardings) {
        super(element);
        this._security = security;
        this._onboardings = onboardings;
    }

    settingsChanged() {
        if (!this.settings) return;
        this._initializingSettings = true;
        this.forAgency = this.settings ? this.settings.forAgency : this._defaultForAgency;
        this.chartType = this.settings ? this.settings.defaultChartType : this.CHART_TYPE.ByStatus;
        this._initializingSettings = false;
    }

    _dispatch() {
        if (this._initializingSettings) return;
        const data = {
            forAgency: this.forAgency || this._defaultForAgency,
            defaultChartType: this.defaultChartType || this.CHART_TYPE.ByStatus,
        };
        this.dispatchSettingsEvent(data);
        this.chartType = data.defaultChartType;
        this.refreshData();
    }

    attached() {
        this._load();
    }

    selectType(chartType) {
        this.chartType = chartType;
    }

    refreshData() {
        this._load();
    }

    async _load() {
        try {
            this.status = await this._onboardings.teamStatus(this.forAgency);
            this._loadCharts();
        } catch (err) {
            console.log(err);
        }
    }

    _loadCharts() {
        this.chartData = {
            steps: this.status.steps,
            countsRYG: this.status.countsRYG,
            onboardings: this.status.onboardings,
        };
    }
}
