import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { c } from 'common/common';

export class WidgetFavoriteCarriers extends WidgetBase {
    static inject = [Element];

    @bindable settings;
    friendlyName;

    constructor(element) {
        super(element);
    }

    settingsChanged() {
        try {
            this._initializingSettings = true;
            this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
        } catch (err) {
            console.log(err);
        } finally {
            this._initializingSettings = false;
        }
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch();
    }

    _dispatch() {
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
        };
        this.dispatchSettingsEvent(data, true);
    }
}
