import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Config } from 'services/config';
import { Contests } from 'services/contests';

export class WidgetContestProgress extends WidgetBase {
    static inject = [Element, Config, Contests];
    _config;
    _contests;

    @bindable settings;
    @observable code = '';
    _defaultCode = '';
    contests;

    constructor(element, config, contests) {
        super(element);
        this._config = config;
        this._contests = contests;
    }

    settingsChanged() {
        try {
            if (this.code) return;
            this._initializingSettings = true;
            this.code = this.settings ? this.settings.code || this._defaultCode : this._defaultCode;
        } catch (err) {
            console.log(err);
        } finally {
            this._initializingSettings = false;
        }
    }

    codeChanged() {
        if (this._initializingSettings) return;
        const data = { code: this.code || this._defaultCode };
        this.dispatchSettingsEvent(data);
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.contests = await this._contests.all();
            if (this.contests.length && !this.code) this.code = this.contests[0].code;
        } catch (err) {
            console.log(err);
        }
    }
}
