import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';

export class WidgetMetrics extends WidgetBase {
    static inject = [Element];

    @bindable settings;
    @bindable isPublished = false;
    @bindable isForAgency = false;
    @bindable canEdit = false;

    constructor(element) {
        super(element);
    }

    metricSettingsChanged(detail) {
        this.metric = detail.data;
        this.dispatchSettingsEvent(detail.data, detail.save);
    }
}
