import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { WidgetBase } from './../widget-base';

export class WidgetDownlineCarrierDebt extends WidgetBase {
    static inject = [Element, Security];

    @bindable settings;

    constructor(element, security) {
        super(element);
        this.memberId = security.authenticatedMemberId;
    }
}
