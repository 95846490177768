import { bindable, observable, computedFrom } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { ProductionService } from 'services/production-service';
import { ROLE } from 'common/constants';
import { c } from 'common/common';

export class WidgetPoliciesByStatus extends WidgetBase {
    static inject = [Element, Security, ProductionService];
    _security;
    _production;

    @bindable settings;

    isAgency = false;
    isMasterAgency = false;
    isDashboardAdmin = false;

    friendlyName;
    @observable scope;
    SCOPE_TYPE = {
        Individual: 'individual',
        Team: 'team',
        Agency: 'agency',
        MasterAgency: 'master-agency',
    };

    statuses;
    selectedStatuses = [];
    _defaultStatuses = ['pending','issued-pending-requirements'];

    policies;

    constructor(element, security, productionService) {
        super(element);
        this._security = security;
        this.isAgency = security.isAgency();
        this.isMasterAgency = security.isMasterAgency();
        this._production = productionService;
        this.isDashboardAdmin = security.isInRole(ROLE.DashboardAdmin);
    }

    settingsChanged() {
        if (!this.settings) return;
        this._initializingSettings = true;
        this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
        let scope = this.SCOPE_TYPE.Individual;
        if (this.settings && this.settings.forAgency === true) scope = this.SCOPE_TYPE.Agency; // old version has forAgency setting
        if (this.settings && this.settings.scope) scope = this.settings.scope;
        this.scope = scope;
        this.selectedStatuses = this.settings ? this.settings.statuses || this._defaultStatuses : this._defaultStatuses;
        this._setSelectedStatuses();
        this._initializingSettings = false;
    }

    scopeChanged() {
        if (!this._attached) return;
        this._dispatch();
    }

    @computedFrom('scope', 'friendlyName')
    get titleKey() {
        if (this.friendlyName) return this.friendlyName;
        if (!this.scope) return undefined;
        return `policies-by-status-${this.scope}`;
    }

    @computedFrom('scope')
    get showName() {
        if (!this.scope) return 'client';
        if (this.scope === this.SCOPE_TYPE.Individual) return 'client';
        return 'agent';
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch(true);
    }

    _dispatch(save = false) {
        if (this._initializingSettings) return;
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
            scope: this.scope || this.SCOPE_TYPE.Individual,
            statuses: this.selectedStatuses ? this.selectedStatuses : this._defaultStatuses,
        };
        this.dispatchSettingsEvent(data, save);
        this.refreshData();
    }

    _setSelectedStatuses() {
        if (!this.statuses || !this.selectedStatuses) return;
        this.statuses.forEach(s => {
            s.checked = this.selectedStatuses.includes(s.key);
        });
    }

    policyStatusChecked(ev, status) {
        ev.stopPropagation();
        var idx = this.selectedStatuses.indexOf(status.key);
        if (idx < 0) {
            this.selectedStatuses.push(status.key);
        } else {
            this.selectedStatuses.splice(idx, 1);
        }
        this._dispatch(true);
    }

    attached() {
        this._attached = true;
        this._load();
    }

    refreshData() {
        this.policies = undefined;
        this._load();
    }

    async _load() {
        try {
            this.refreshing = true;
            this.policies = await this._production.searchPolicies(this.scope, this.selectedStatuses);
            if (!this.statuses) {
                const statuses = await this._production.policyStatuses();
                this.statuses = [];
                statuses.forEach(s => this.statuses.push({ key: s, checked: false }));
                this._setSelectedStatuses();
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.refreshing = false;
        }
    }
}
