import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { ProductionService } from 'services/production-service';

export class WidgetPendingAnnuities extends WidgetBase {
    static inject = [Element, Security, ProductionService];
    _security;
    _production;

    @bindable settings;

    annuities;

    constructor(element, security, productionService) {
        super(element);
        this._security = security;
        this._production = productionService;
    }

    settingsChanged() {
    }

    attached() {
        this._load();
    }

    refreshData() {
        this.annuities = undefined;
        this._load();
    }

    async _load() {
        try {
            this.annuities = await this._production.pendingAnnuities();
        } catch (err) {
            console.log(err);
        }
    }
}
