import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Config } from 'services/config';
import { Leaderboards } from 'services/leaderboards';
import { LOB } from 'common/constants';

export class WidgetLeaderboardStandings extends WidgetBase {
    static inject = [Element, Config, Leaderboards];
    _config;
    _leaderboards;

    @bindable config;
    @bindable settings;
    @observable({ changeHandler: '_dispatch' }) view;
    @observable({ changeHandler: '_dispatch' }) code = '';
    _defaultCode = '';
    @observable({ changeHandler: '_dispatch' }) lineOfBusiness;
    _defaultLineOfBusiness = LOB.Life;
    lobs = [LOB.Life, LOB.Annuity, LOB.HMA];
    _defaultView = 'list';
    views = ['list', 'podium'];

    leaderboards;

    constructor(element, config, leaderboards) {
        super(element);
        this._config = config;
        this._leaderboards = leaderboards;
    }

    configChanged() {
        try {
            if (this.config) {
                this.priorWeeksAllowed = this.config.priorWeeksAllowed;
            }
        } catch (err) {
            console.log(err);
        }
    }

    settingsChanged() {
        if (!this.settings) return;
        this._initializingSettings = true;
        this.view = this.settings ? this.settings.view || this._defaultView : this._defaultView;
        this.code = this.settings ? this.settings.code || this._defaultCode : this._defaultCode;
        this.lineOfBusiness = this.settings ? this.settings.lineOfBusiness || this._defaultLineOfBusiness : this._defaultLineOfBusiness;
        this._initializingSettings = false;
    }

    _dispatch() {
        if (this._initializingSettings) return;
        const data = {
            view: this.view || this._defaultView,
            code: this.code || this._defaultCode,
            lineOfBusiness: this.lineOfBusiness || this._defaultLineOfBusiness,
        };
        this.dispatchSettingsEvent(data);
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.leaderboards = await this._leaderboards.all();
            if (this.leaderboards.length && !this.code) this.code = this.leaderboards[0].code;
        } catch (err) {
            console.log(err);
        }
    }
}
