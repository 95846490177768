import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { Config } from 'services/config';
import { ProductionService } from 'services/production-service';
import { METRIC_TYPE, POLICY_STATUS_GROUP, POLICY_DATE_TYPE, REPORT_TIME_PERIOD } from 'common/constants';

export class WidgetByCarrierMetrics extends WidgetBase {
    static inject = [Element, Security, Config, ProductionService];
    _security;
    _config;
    _production;

    @bindable settings;

    @observable({ changeHandler: '_dispatch' }) chartType;
    _defaultChartType = 'pie';
    chartTypes = ['pie', 'bar'];

    @observable({ changeHandler: '_dispatch' }) metricType;
    _defaultMetricType = METRIC_TYPE.Count;
    METRIC_TYPES = METRIC_TYPE.all();
    METRIC_TYPE = METRIC_TYPE;

    @observable({ changeHandler: '_dispatch' }) dateType;
    _defaultDateType = '';
    DATE_TYPES = POLICY_DATE_TYPE.all();

    @observable({ changeHandler: '_dispatch' }) timePeriod = '';
    _defaultTimePeriod = REPORT_TIME_PERIOD.MTD;
    TIME_PERIODS = REPORT_TIME_PERIOD.all();

    @observable({ changeHandler: '_dispatch' }) policyStatusGroup;
    _defaultPolicyStatusGroup = POLICY_STATUS_GROUP.InForce;
    POLICY_STATUS_GROUPS = POLICY_STATUS_GROUP.all();

    @observable({ changeHandler: '_dispatch' }) premiumType;
    _defaultPremiumType = '';

    @observable({ changeHandler: '_dispatch' }) sync;
    _defaultSync = true;

    leaderboards;

    constructor(element, security, config, production) {
        super(element);
        this._security = security;
        this._config = config;
        this._production = production;
    }

    settingsChanged() {
        if (!this.settings) return;
        this._initializingSettings = true;
        this.chartType = this.settings ? this.settings.chartType || this._defaultChartType : this._defaultChartType;
        this.metricType = this.settings ? this.settings.metricType || this._defaultMetricType : this._defaultMetricType;
        this.dateType = this.settings ? this.settings.dateType || this._defaultDateType : this._defaultDateType;
        this.timePeriod = this.settings ? this.settings.timePeriod || this._defaultTimePeriod : this._defaultTimePeriod;
        this.policyStatusGroup = this.settings ? this.settings.policyStatusGroup || this._defaultPolicyStatusGroup : this._defaultPolicyStatusGroup;
        this.premiumType = this.settings ? this.settings.premiumType || this._defaultPremiumType : this._defaultPremiumType;
        this.sync = this.settings ? (this.settings.sync === true || this.settings.sync === false) ? this.settings.sync : this._defaultSync : this._defaultSync;
        this._initializingSettings = false;
    }

    _dispatch() {
        if (this._initializingSettings) return;
        const data = {
            chartType: this.chartType || this._defaultChartType,
            metricType: this.metricType || this._defaultMetricType,
            dateType: this.dateType || this._defaultDateType,
            timePeriod: this.timePeriod || this._defaultTimePeriod,
            policyStatusGroup: this.policyStatusGroup || this._defaultPolicyStatusGroup,
            premiumType: this.premiumType || this._defaultPremiumType,
            sync: (this.sync === true || this.sync === false) ? this.sync : this._defaultSync,
        };
        this.dispatchSettingsEvent(data);
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.premiumTypes = await this._production.premiumTypes();
            this._defaultPremiumType = this.premiumTypes[0];
        } catch (err) {
            console.log(err);
        }
    }
}
