import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';

export class WidgetKpi extends WidgetBase {
    static inject = [Element];

    @bindable canEdit;
    @bindable settings;

    constructor(element) {
        super(element);
    }

    kpiSettingsChanged(detail) {
        console.log('widget-kpi', detail);
        this.kpi = detail.data;
        this.dispatchSettingsEvent(detail.data, detail.save);
    }
}
