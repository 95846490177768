import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Config } from 'services/config';
import { Content } from 'services/content';

export class WidgetLpfnContent extends WidgetBase {
    static inject = [Element, Config, Content];
    _config;
    _content;

    @bindable settings;
    @observable key = '';
    uiWidgets;

    constructor(element, config, content) {
        super(element);
        this._config = config;
        this._content = content;
    }

    settingsChanged() {
        this.key = this.settings ? this.settings.key || '' : '';
    }

    keyChanged() {
        const data = { key: this.key || '' };
        this.dispatchSettingsEvent(data);
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.uiWidgets = await this._content.allByType('DashboardWidget');
            if (this.uiWidgets.length && !this.key) this.key = this.uiWidgets[0].key;
        } catch (err) {
            console.log(err);
        }
    }
}
