import { bindable, observable } from 'aurelia-framework';
import { CACHE_TYPE, CACHE_PERIOD } from 'services/production-service';
import { WidgetBase } from './../widget-base';
import { c } from 'common/common';

export class WidgetProductionStatus extends WidgetBase {
    static inject = [Element];

    @bindable settings;
    friendlyName;
    @observable({ changeHandler: '_dispatch' }) cachePeriod;
    @observable({ changeHandler: '_dispatch' }) cacheType;

    cachePeriods = CACHE_PERIOD.options();
    cacheTypes = CACHE_TYPE.options();

    constructor(element) {
        super(element);
    }

    settingsChanged() {
        if (!this.settings) return;

        this._initializingSettings = true;
        this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
        this.cachePeriod = this.settings ? this.settings.cachePeriod || CACHE_PERIOD.Yesterday : CACHE_PERIOD.Yesterday;
        this.cacheType = this.settings ? this.settings.cacheType || CACHE_TYPE.Personal : CACHE_TYPE.Personal;
        this._initializingSettings = false;
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch(true);
    }

    _dispatch(save = false) {
        if (this._initializingSettings) return;
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
            cachePeriod: this.cachePeriod,
            cacheType: this.cacheType,
        };
        this.dispatchSettingsEvent(data, save);
    }
}
