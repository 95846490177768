import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { WIDGET, TIME_PERIOD } from 'common/constants';
import { LEAD_KPI } from 'services/leads';

export class WidgetLeadKpi extends WidgetBase {
    static inject = [Element, Security];

    @bindable config;
    @bindable settings;

    viewOptions;
    _defaultView;
    @observable({ changeHandler: '_dispatch'}) view;
    kpiOptions = LEAD_KPI.all();
    @observable({ changeHandler: '_dispatch' }) kpi;
    _defaultKpi = LEAD_KPI.default();
    timePeriodOptions = TIME_PERIOD.common(false);
    @observable({ changeHandler: '_dispatch' }) timePeriod;
    _defaultTimePeriod = TIME_PERIOD.default();

    constructor(element, security) {
        super(element);

        this.viewOptions = WIDGET.View.all(security, false);
    }

    configChanged() {
        try {
            if (this.config) {
            }
        } catch (err) {
            console.log(err);
        }
    }

    settingsChanged() {
        if (!this.settings) return;
        this._initializingSettings = true;
        this.view = this.settings ? this.settings.view || this._defaultView : this._defaultView;
        this.kpi = this.settings ? this.settings.kpi || this._defaultKpi : this._defaultKpi;
        this.timePeriod = this.settings ? this.settings.timePeriod || this._defaultTimePeriod : this._defaultTimePeriod;
        this._initializingSettings = false;
    }

    _dispatch() {
        if (this._initializingSettings) return;
        const data = {
            view: this.view || this._defaultView,
            kpi: this.kpi || this._defaultKpi,
            timePeriod: this.timePeriod || this._defaultTimePeriod,
        };
        this.dispatchSettingsEvent(data);
    }
}
