import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { WIDGET } from 'common/constants';

export class WidgetDownlinePersonalBonus extends WidgetBase {
    static inject = [Element, Security];

    @bindable config;
    @bindable settings;

    _defaultSync = true;
    @observable({ changeHandler: '_dispatch' }) sync;
    viewOptions;
    _defaultView;
    @observable({ changeHandler: '_dispatch'}) view;

    constructor(element, security) {
        super(element);

        this.viewOptions = WIDGET.View.all(security, false);
    }

    configChanged() {
        try {
            if (this.config) {
                this.priorMonthsAllowed = this.config.priorMonthsAllowed;
            }
        } catch (err) {
            console.log(err);
        }
    }

    settingsChanged() {
        if (!this.settings) return;
        this._initializingSettings = true;
        this.sync = this.settings ? (this.settings.sync === true || this.settings.sync === false) ? this.settings.sync : this._defaultSync : this._defaultSync;
        this.view = this.settings ? this.settings.view || this._defaultView : this._defaultView;
        this._initializingSettings = false;
    }

    _dispatch() {
        if (this._initializingSettings) return;
        const data = {
            sync: (this.sync === true || this.sync === false) ? this.sync : this._defaultSync,
            view: this.view || this._defaultView,
        };
        this.dispatchSettingsEvent(data);
    }
}
