import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Kpis } from 'services/kpis';

export class WidgetKpiSimple extends WidgetBase {
    static inject = [Element, Kpis];
    _kpis;

    @bindable settings;

    @observable selectedKpi;

    @observable({ changeHandler: '_dispatch' }) sync;
    _defaultSync = true;

    availableKpis;

    constructor(element, kpis) {
        super(element);
        this._kpis = kpis;
    }

    kpiMatcher = (a, b) => {
        if (!a || !a.kpiType || !b || !b.kpiType) return false;
        return a.kpiType === b.kpiType && a.kpiFor === b.kpiFor && a.kpiPeriod === b.kpiPeriod;
    }

    settingsChanged() {
        if (!this.settings) return;
        if (this.kpi && this.settings && this.settings.kpi && this.kpiMatcher(this.kpi, this.settings.kpi)) return;
        this._initializingSettings = true;
        this.kpi = this.settings ? this.settings.kpi : null;
        if (this.kpi) this.selectedKpi = JSON.parse(JSON.stringify(this.kpi));
        this.sync = this.settings ? (this.settings.sync === true || this.settings.sync === false) ? this.settings.sync : this._defaultSync : this._defaultSync;
        this._initializingSettings = false;
    }

    selectedKpiChanged() {
        if (!this.selectedKpi) return;
        if (this.kpi && this.kpiMatcher(this.kpi, this.selectedKpi)) return;
        this.kpi = this.selectedKpi;
        this._dispatch();
    }

    _dispatch() {
        if (this._initializingSettings) return;
        this.kpi = this.selectedKpi;
        const data = {
            kpi: this.kpi || null,
            sync: (this.sync === true || this.sync === false) ? this.sync : this._defaultSync,
        };
        this.dispatchSettingsEvent(data);
    }

    async attached() {
        await this._load();
    }

    async _load() {
        try {
            this.refreshing = true;
            if (!this.availableKpis) {
                this.availableKpis = await this._kpis.allAvailable();
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.refreshing = false;
        }
    }
}
