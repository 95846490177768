import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { Bonuses } from 'services/bonuses';
import moment from 'moment';

export class WidgetPersonalBonus extends WidgetBase {
    static inject = [Element, Security, Bonuses];
    _security;
    _bonuses;

    @bindable config;
    @bindable settings;

    currentDate;
    summary;

    constructor(element, security, bonuses) {
        super(element);
        this._security = security;
        this._bonuses = bonuses;
    }

    configChanged() {
        try {
            if (this.config) {
                this.priorMonthsAllowed = this.config.priorMonthsAllowed;
            }
        } catch (err) {
            console.log(err);
        }
    }

    settingsChanged() {
    }

    attached() {
        this._load();
    }

    refreshData() {
        this.summary = undefined;
        this._load();
    }

    previous() {
        if (!this.canGoPrevious) return;
        this.currentDate = this.currentDate.add(-1, 'M');
        this._load();
    }

    next() {
        if (!this.canGoNext) return;
        this.currentDate = this.currentDate.add(1, 'M');
        this._load();
    }

    current() {
        this.currentDate = undefined;
        this._load();
    }

    async _load() {
        try {
            let initialLoad = false;
            if (!this.currentDate) {
                initialLoad = true;
                this.currentDate = moment();
            }
            this.summary = await this._bonuses.personalSummary(this._security.authenticatedMemberId, this.currentDate);
            if (initialLoad) this.currentDate = moment(this.summary.bonus.end).startOf('month').add(1, 'day'); // After getting the initial date, need to set the current date based on the time period, the first of the month (of the end date) is the current month, adjust to the 2nd for time zone adjustment
            const nextTimePeriodStartDate = moment(this.summary.bonus.end).add(1, 'day');
            this.canGoNext = !nextTimePeriodStartDate.isAfter(moment().endOf('day'));
            const start = moment(this.summary.bonus.start);
            if (initialLoad && this.priorMonthsAllowed !== undefined) {
                const goBackMonths = -1 * this.priorMonthsAllowed;
                this.earliestDate = start.clone().add(goBackMonths, 'month');
            }
            if (!this.earliestDate) this.canGoPrevious = true;
            else {
                const previousPeriodStartDate = start.clone().add(-1, 'month');
                this.canGoPrevious = previousPeriodStartDate.isSameOrAfter(this.earliestDate);
            }
        } catch (err) {
            console.log(err);
        }
    }

    togglePlacementDetails() {
        this.showPlacementDetails = !this.showPlacementDetails;
        this.showDrawer = moment().format();
    }

    drawerClosed() {}
}
