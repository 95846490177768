import { bindable } from 'aurelia-framework';
import { Products } from 'services/products';
import { LOB } from 'common/constants';

export class SettingsFooter {
    static inject = [Products];
    _products;
    @bindable settings;

    constructor(products) {
        this._products = products;
    }

    attached() {
        this._loadProductTypes();
    }

    async _loadProductTypes() {
        try {
            const productTypes = await this._products.typesByLOB();
            this.totalLifeProductTypes = productTypes.find(x => x.lineOfBusiness === LOB.Life).productTypes.length;
        } catch (err) {
            console.log(err);
        }
    }
}