import { bindable, observable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Config } from 'services/config';
import { c } from 'common/common';

export class WidgetGoogleCalendar extends WidgetBase {
    static inject = [Element, Config];
    _config;

    @bindable settings;
    friendlyName;
    @observable({ changeHandler: '_dispatch' }) view = 'AGENDA';
    calendarSrc;

    constructor(element, config) {
        super(element);
        this._config = config;
    }

    settingsChanged() {
        try {
            this._initializingSettings = true;
            this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
            this.view = this.settings ? this.settings.view || 'AGENDA' : 'AGENDA';
        } catch (err) {
            console.log(err);
        } finally {
            this._initializingSettings = false;
        }
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch();
    }

    _dispatch() {
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
            view: this.view || 'AGENDA'
        };
        this.dispatchSettingsEvent(data, true);
        this._setCalendarSrc();
    }

    attached() {
        this._setCalendarSrc();
    }

    _setCalendarSrc() {
        if (!this.calendarContainerEl) return;
        const googleCalendarSrc = this._config.value('googleCalendarSource');
        let src = `https://calendar.google.com/calendar/embed?src=${encodeURIComponent(googleCalendarSrc)}&ctz=America%2FNew_York&mode=${encodeURIComponent(this.view)}&showNav=1&showTitle=0&showDate=0&showPrint=1&showTabs=0&showCalendars=0`;
        this.calendarContainerEl.innerHTML = `<iframe src="${src}" style="border: 0; width: 100%;" frameborder="0" scrolling="no"></iframe>`;
    }
}
