import { PLATFORM } from 'aurelia-pal';

const widgetGlobalResources = [
    PLATFORM.moduleName('resources/widgets/badges/widget-badge-assignments'),
    PLATFORM.moduleName('resources/widgets/bonuses/widget-agency-bonus'),
    PLATFORM.moduleName('resources/widgets/bonuses/widget-downline-personal-bonus'),
    PLATFORM.moduleName('resources/widgets/bonuses/widget-personal-bonus'),
    PLATFORM.moduleName('resources/widgets/content/widget-lpfn-content'),
    PLATFORM.moduleName('resources/widgets/contest/widget-contest-progress'),
    PLATFORM.moduleName('resources/widgets/contest/widget-contest-standings'),
    PLATFORM.moduleName('resources/widgets/contest/widget-monthly-consistency'),
    PLATFORM.moduleName('resources/widgets/debt/widget-downline-carrier-debt'),
    PLATFORM.moduleName('resources/widgets/favorites/widget-favorite-carriers'),
    PLATFORM.moduleName('resources/widgets/google-calendar/widget-google-calendar'),
    PLATFORM.moduleName('resources/widgets/hierarchy/widget-contracted-in-state'),
    PLATFORM.moduleName('resources/widgets/hierarchy/widget-contracting-hierarchy'),
    PLATFORM.moduleName('resources/widgets/leaderboards/widget-leaderboard-standings'),
    PLATFORM.moduleName('resources/widgets/leads/widget-lead-kpi'),
    PLATFORM.moduleName('resources/widgets/metrics/widget-metrics'),
    PLATFORM.moduleName('resources/widgets/notifications/widget-notifications'),
    PLATFORM.moduleName('resources/widgets/onboarding/widget-onboarding-status-chart'),
    PLATFORM.moduleName('resources/widgets/pending-annuities/widget-pending-annuities'),
    PLATFORM.moduleName('resources/widgets/policies/widget-policies-by-status'),
    PLATFORM.moduleName('resources/widgets/production/widget-by-agency-metrics'),
    PLATFORM.moduleName('resources/widgets/production/widget-by-agent-metrics'),
    PLATFORM.moduleName('resources/widgets/production/widget-by-carrier-metrics'),
    PLATFORM.moduleName('resources/widgets/production/widget-by-team-metrics'),
    PLATFORM.moduleName('resources/widgets/production/widget-volume-rankings'),
    PLATFORM.moduleName('resources/widgets/production-summary/widget-production-status'),
    PLATFORM.moduleName('resources/widgets/production-summary/widget-production-summary'),
    PLATFORM.moduleName('resources/widgets/to-dos/widget-to-dos'),
    PLATFORM.moduleName('resources/widgets/kpis/widget-kpi'),
    PLATFORM.moduleName('resources/widgets/kpis/widget-kpi-simple'),
    PLATFORM.moduleName('resources/widgets/kpis/widget-kpi-health'),
];

export default widgetGlobalResources;