import { observable, bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';
import { Security } from 'common/security';
import { Config } from 'services/config';
import { WIDGET, TIME_PERIOD } from 'common/constants';

export class WidgetNotifications extends WidgetBase {
    static inject = [Element, Security, Config];
    _config;

    @bindable settings;
    viewOptions;
    _defaultView;
    @observable({ changeHandler: '_dispatch'}) view;
    @observable timePeriod = TIME_PERIOD.Today;
    _defaultTimePeriod = TIME_PERIOD.Today;
    timePeriods = [TIME_PERIOD.Today, TIME_PERIOD.ThisWeek, TIME_PERIOD.ThisMonth, TIME_PERIOD.LastMonth];

    notificationTypes = ['agent-birthday', 'client-birthday', 'policy-anniversary', 'agent-first-contract', 'agent-first-policy-submitted', 'agent-first-policy-paid'];
    selectedNotificationTypes = [];
    boundNotificationTypes;
    _defaultNotificationTypes = ['agent-birthday', 'client-birthday', 'policy-anniversary', 'agent-first-contract', 'agent-first-policy-submitted', 'agent-first-policy-paid'];

    constructor(element, security, config) {
        super(element);
        this._config = config;
        this.viewOptions = WIDGET.View.all(security, false);
        this._defaultView = WIDGET.View.default();
    }

    settingsChanged() {
        this.view = this.settings ? this.settings.view || this._defaultView : this._defaultView;
        this.timePeriod = this.settings ? this.settings.timePeriod || this._defaultTimePeriod : this._defaultTimePeriod;
        this.selectedNotificationTypes = this.settings ? this.settings.notificationTypes || this._defaultNotificationTypes : this._defaultNotificationTypes;
        this.boundNotificationTypes = this.selectedNotificationTypes.join(',');
    }

    timePeriodChanged() {
        this._dispatch();
    }

    notificationTypeChanged() {
        this.boundNotificationTypes = this.selectedNotificationTypes.join(',');
        this._dispatch();
    }

    _dispatch() {
        const data = {
            view: this.view || this._defaultView,
            timePeriod: this.timePeriod || this._defaultTimePeriod,
            notificationTypes: this.selectedNotificationTypes || this._defaultNotificationTypes,
        };
        this.dispatchSettingsEvent(data);
    }
}
