import { bindable } from 'aurelia-framework';
import { WidgetBase } from './../widget-base';

export class WidgetBadgeAssignments extends WidgetBase {
    static inject = [Element];

    @bindable settings;

    constructor(element) {
        super(element);
    }
}
